import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "vscode-for-data-science"
    }}><a parentName="h1" {...{
        "href": "#vscode-for-data-science"
      }}>{`VSCode for data science`}</a></h1>
    <p>{`There are several well-known challenges with using Jupyter notebooks for data science on a team, but many people don't know that VSCode addresses many of those shortcomings via its free Jupyter and Live Share plugins, its new devcontainer functionality, and its paid CodeSpaces offering. In the last 18 months, Microsoft has significantly increased its investment in these tools. Let's look at each challenge and see how VSCode/Github addresses them.`}</p>
    <h2 {...{
      "id": "collaboration"
    }}><a parentName="h2" {...{
        "href": "#collaboration"
      }}>{`Collaboration`}</a></h2>
    <p>{`VSCode has a `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=MS-vsliveshare.vsliveshare-pack",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`live share plugin`}</a>{` that enables google-docs-style real-time collaboration on Jupyter notebooks and on code files in general. All you need is a Github account. I've used it before and it's quite good. Here's a demo:`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/A2ceblXTBBc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2 {...{
      "id": "version-control"
    }}><a parentName="h2" {...{
        "href": "#version-control"
      }}>{`Version Control`}</a></h2>
    <p>{`VSCode has excellent notebook diffing support that hides the messy json of the notebook format. Here's what it looks like:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "465px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/559c573981a873b782f86f0dd269e7c0/51ce3/diff.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRkoAAABXRUJQVlA4ID4AAACwAwCdASoUAA8APtFUo0uoJKMhsAgBABoJaQAAW+qtdiIilU6YAAD+9M4OJHFJ8m3mQWkgICQhdwHXaDxwAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "diff",
            "title": "diff",
            "src": "/static/559c573981a873b782f86f0dd269e7c0/51ce3/diff.webp",
            "srcSet": ["/static/559c573981a873b782f86f0dd269e7c0/c85cb/diff.webp 300w", "/static/559c573981a873b782f86f0dd269e7c0/51ce3/diff.webp 465w"],
            "sizes": "(max-width: 465px) 100vw, 465px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can diff notebooks locally simply by clicking the version control view within VSCode, and you can diff notebooks within a PR by using `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=GitHub.vscode-pull-request-github",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`the Github Issues and Pull Requests plugin.`}</a></p>
    <h2 {...{
      "id": "reproducibility"
    }}><a parentName="h2" {...{
        "href": "#reproducibility"
      }}>{`Reproducibility`}</a></h2>
    <p>{`"It works on my machine" is the opposite of reproducibility, and Microsoft's devcontainers spec is designed to address this exact issue. It's a docker-based, open standard that's specifically designed to work with IDEs like VSCode.`}</p>
    <p>{`It's developer focus makes it easier to create a container that captures all the python dependencies used for a particular data science project, and its possible to do this without having to know much about Docker. In fact, with their devcontainer templates, you can spin up a container based on miniconda through the VSCode UI without touching a docker file at all:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e8b94323c9c40aed163d49a4ce588c15/e216b/conda-container.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABp0lEQVQoz52S3XKbMBCFeRAbsBFCEuJP/wTiJG2T1jed6V3f/0VOR2u7k+llL77RrqSFs2dVbE8R1jqM44jZLJjMjGmesa4b0rrh8vKG948fxOvbF8T0hBAThmH8xEB4H1BYF7BtOzjv0PIW/aDBuw6zcdj2C9aYkO/Mi70XjpBSoqqqv5xOJ9R1jePxiMJYjxgjGGPoOIeZJ8y6xxQuCC9XhOdvWNIrRv+Mwe3QbocYHPq+p64yXddRfdM0KLQe0LCWEtYJyGEBFxI6vcN//Ib/+gvT5SfUeoWI3yHTFWzaSVlWVdcnUnY4HIii7zWahpFsISWMdXDOwzuLGBzW6BG9RfSGYm8XuIxzMMbAWktxJltSZMmMtTifz+TjME4Yp5m80lrTpfxTqRR6ffO3bTk459Rmpm1byjNFCLcp55a5UFCjgZosOqnIm5ZzWoUQtGYexfx+9vhgtqFYFgMhJHmhlEQMnkgpIaaEEALF3t+Gl+57j/3PZ3lQhVI9eUgtZ4WTAZMDzg0j07O3j2fxiDP5/r85KZRS3T1sUJYljmWFsrq9qf/hDzOsLWjJONZkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "conda container",
            "title": "conda container",
            "src": "/static/e8b94323c9c40aed163d49a4ce588c15/c1b63/conda-container.png",
            "srcSet": ["/static/e8b94323c9c40aed163d49a4ce588c15/5a46d/conda-container.png 300w", "/static/e8b94323c9c40aed163d49a4ce588c15/0a47e/conda-container.png 600w", "/static/e8b94323c9c40aed163d49a4ce588c15/c1b63/conda-container.png 1200w", "/static/e8b94323c9c40aed163d49a4ce588c15/d61c2/conda-container.png 1800w", "/static/e8b94323c9c40aed163d49a4ce588c15/e216b/conda-container.png 1904w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "scaling-computation"
    }}><a parentName="h2" {...{
        "href": "#scaling-computation"
      }}>{`Scaling Computation`}</a></h2>
    <p>{`The devcontainer spec works seemlessly with another Github/Microsoft offering called "CodeSpaces." Once you've got a container specified, CodeSpaces can run your dev environment in the cloud on whatever hardware you like (including GPUs).`}</p>
    <p>{`This isn't free, but's it's quite reasonably priced: the first 120 core hours are free and after that, you can get a quad core machine for .36/hr. If you ran your CodeSpace during the entire work week, that's only 47 dollars. It's unlikely your CodeSpace will run 40 hours a week, however, as your data scientists have other work aside from coding and Code Spaces automatically stop running after 30-minutes of inactivity.`}</p>
    <h2 {...{
      "id": "polyglot-workflows"
    }}><a parentName="h2" {...{
        "href": "#polyglot-workflows"
      }}>{`Polyglot Workflows`}</a></h2>
    <p>{`Data Scientists often need to work across Python and SQL, and while this is very possible using `}<a parentName="p" {...{
        "href": "https://github.com/catherinedevlin/ipython-sql",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`the ipython SQL magic`}</a>{`, Microsoft is working on an even smoother experience via Polyglot notebooks. This isn't released yet, but `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=ms-dotnettools.dotnet-interactive-vscode",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`it looks promising`}</a>{`.`}</p>
    <hr></hr>
    <p>{`If you need help getting started using VSCode/Devcontainers/CodeSpaces for your data science team, shoot me an email. You can reach me at `}<a parentName="p" {...{
        "href": "mailto:matt@datachimp.app"
      }}>{`matt@datachimp.app`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      